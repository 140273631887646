import { Outlet, useLocation } from "react-router-dom";
import { redirectAfterDelay } from "../../utils/utils";
import withUserData from "../../HOC/withUserData";


//Order of precedence
const tabs = [
  "SIGNUP", "VERIFY", "PIN"
];

const SignupIndex = ({isLoggedAsGuest = true, isLoggedIn}) => {
  if (!isLoggedAsGuest && isLoggedIn) {
    redirectAfterDelay('/', 0);
  }

  return (
    <div className="bg-white">
      <Outlet />
    </div>
  );
};

export default withUserData(SignupIndex);
