import React from "react";

const TrackExpenses = ({ onClick, size = 20 }) => {
  return (
    <svg
      style={{ width: size, height: size }}
      onClick={onClick}
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.156494"
        y="0.329102"
        width="29.8426"
        height="29.8426"
        rx="8.95278"
        fill="#af8b36"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4972 9.83152C17.4972 11.4885 18.8424 12.8318 20.5019 12.8318C20.6846 12.8309 20.8669 12.8134 21.0464 12.7794V18.7275C21.0464 21.2299 19.5703 22.7104 17.0642 22.7104H11.606C9.09331 22.7104 7.61722 21.2299 7.61722 18.7275V13.2772C7.61722 10.7748 9.09331 9.28125 11.606 9.28125H17.5497C17.5144 9.46255 17.4968 9.64683 17.4972 9.83152ZM15.9358 17.4108L18.068 14.6595V14.6464C18.2505 14.4011 18.2039 14.0553 17.963 13.8668C17.8464 13.7768 17.6982 13.7379 17.5523 13.7588C17.4064 13.7798 17.2753 13.859 17.1889 13.9782L15.3913 16.2906L13.3445 14.6791C13.2276 14.5881 13.0789 14.5479 12.932 14.5676C12.7851 14.5873 12.6524 14.6653 12.5638 14.784L10.3595 17.627C10.282 17.7236 10.2403 17.8439 10.2414 17.9676C10.2286 18.2177 10.3865 18.4449 10.6256 18.5204C10.8647 18.5959 11.1247 18.5006 11.2582 18.2886L13.1017 15.9041L15.1486 17.5091C15.265 17.6029 15.4146 17.6455 15.5631 17.627C15.7116 17.6084 15.8461 17.5304 15.9358 17.4108Z"
        fill="#FDFDFD"
      />
      <ellipse
        opacity="0.4"
        cx="20.6733"
        cy="9.65423"
        rx="1.86516"
        ry="1.86516"
        fill="#FDFDFD"
      />
    </svg>
  );
};

export default TrackExpenses;
