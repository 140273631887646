import React, { useEffect, useState } from "react";
import ScrollToTopOnMount from "../../components/ScrollToTop/ScrollToTop";
import { AllArticles, Hero } from "./TermsOfUse.helper";

const TermsOfUse = () => {

  const [sticky, setSticky] = useState(false);
  const stickyScrollHandler = () => {
    if (window.scrollY >= 75) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  window.addEventListener("scroll", stickyScrollHandler);

  const ArticleScroll = () => {
    useEffect(() => {
      const asideLinks = document.querySelectorAll(
        ".termsOfUse__inner_content a"
      );

      const handleClick = function (event) {
        event.preventDefault();

        const targetId = this.getAttribute("href").slice(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };

      asideLinks.forEach((link) => {
        link.addEventListener("click", handleClick);
      });

      return () => {
        asideLinks.forEach((link) => {
          link.removeEventListener("click", handleClick);
        });
      };
    }, []);

    return null;
  };

  return (
    <>
      <ScrollToTopOnMount />
      <section className="termsOfUse">
        <div className="termsOfUse__inner">
          <div
            className={
              sticky
                ? "termsOfUse__inner_text-content scrolled"
                : "termsOfUse__inner_text-content"
            }
          >
            <h1 id="termsArticle1">Terms of Use</h1>
            <Hero />
            <AllArticles />
          </div>
          <aside
            className={
              sticky
                ? "termsOfUse__inner_content sticky"
                : "termsOfUse__inner_content"
            }
          >
            <ArticleScroll />
            <p>Contents</p>
            <a href="#termsArticle1">1. Definitions</a>
            <a href="#termsArticle2">2. eligibility</a>
            <a href="#termsArticle3">3. CHARGES AND PAYMENT TERMS</a>
            <a href="#termsArticle4">4. SECURITY AND UNAUTHORIZED USE</a>
            <a href="#termsArticle5">5. Liability</a>
            <a href="#termsArticle6">
              6. REVIEW OF TERMS AND CONDITIONS OF SERVICE
            </a>
            <a href="#termsArticle7">7. notices</a>
            <a href="#termsArticle8">8. SUSPENSION OF ACCESS TO LCEEDATA CONNECTIONS</a>
            <a href="#termsArticle9">9. UNACCEPTABLE USE</a>
            <a href="#termsArticle10">10. INTELLECTUAL PROPERTY</a>
          </aside>
        </div>
      </section>
    </>
  );
};

export default TermsOfUse;
