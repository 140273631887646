import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import OtpInput from "react-otp-input";
import SpButton from "../../sp-components/SpButton/SpButton";
import Layout from "./Layout";
import { sendOtp, verifyOtp } from "../../redux/actions/otpVerification";
import SpMessage from "../../sp-components/SpMessage/SpMessage";
import { getNavPath } from './../../routes/routes.helpers';
import { NAV_SIGN_UP } from './../../routes/routes.constant';
import { getAltOtpChannel, sleep } from "../../utils/utils";

const EnterCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const otpLength = 6;

  const [otp, setOtp] = useState("");
  const [countdownTimer, setCountdownTimer] = useState(0);


  const [formData, setFormData] = useState(location.state || {});

  const otpSendState = useSelector(state => state.otpVerification.send);
  const otpVerifyState = useSelector(state => state.otpVerification.verify);

  const { loading: otpSendLoading } = otpSendState;
  const { loading: otpVerifyLoading, error: otpVerifyError } = otpVerifyState;

  const handleSubmit = useCallback(() => {
    dispatch(verifyOtp({
      ...formData.otp_meta,
      recipient: formData?.otp_meta?.recipient?? formData.phone_number,
      otp_code: otp
    }))
      .then((response) => {
        message.success(response?.message);
        navigate(`${getNavPath(NAV_SIGN_UP)}/pin`, { state: formData });
      })
      .catch(_ => {
        setOtp("");
      });
  }, [dispatch, formData, otp]);


  const handleKeyUp = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }, []);

  useEffect(() => {
    if (countdownTimer > 0) {
      const timerId = setTimeout(() => setCountdownTimer(prev => prev - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [countdownTimer]);

  const resendCode = useCallback(() => {
    // Set resending to true to prevent duplicate requests
    setCountdownTimer(60);

    // Dispatch the action and handle the promise
    dispatch(sendOtp({ ...formData.otp_meta, recipient: formData?.otp_meta?.recipient?? formData.phone_number }))
      .then(res => {
        message.success(res?.message?? `OTP sent via ${formData?.otp_meta?.channel?.toLowerCase()}`)
      }).error((err) => {
        message.error(err)
      });
  }, [dispatch, formData]);


  const loading = otpSendLoading || otpVerifyLoading;
  const isDisabled = loading || otp.length !== otpLength;

  return (
    <div className="auth">
      <Layout
        title="Verify OTP"
        subTitle="Please enter the code we sent to you"
      >
        <div className="form">
          <div className="justify-content-center">
            <OtpInput
              isDisabled={loading}
              value={otp}
              onChange={setOtp}
              numInputs={otpLength}
              className="otp-input"
              separator={<div style={{ marginRight: 15 }} />}
              shouldAutoFocus
              isInputNum
              onKeyUp={handleKeyUp}
            />
          </div>
          <SpMessage
            type='danger'
            visible={!!otpVerifyError && !loading}
            style={{ marginLeft: 22 }}
          >
            {otpVerifyError}
          </SpMessage>
          <br />
          {countdownTimer === 0 ? (
            <p onClick={resendCode} className="resend text-align-center">Resend Code</p>
          ) : (
            <p className="resend text-align-center">Resending in {countdownTimer}</p>
          )}
          <div className="auth__button">
            <SpButton loading={loading} disabled={isDisabled} onClick={handleSubmit}>Continue</SpButton>
          </div>

          <p
            onClick={() => {
              const otpMeta = getAltOtpChannel(formData);

              setFormData((prev) => ({
                ...prev,
                otp_meta: otpMeta,
              }));

              dispatch(sendOtp({ ...otpMeta})).then(res => {
                message.success(res?.message?? `OTP sent via ${otpMeta?.channel?.toLowerCase()}`)
              }).error((err) => {
                message.error(err)
              });
            }}
            className="resend text-align-center"
            style={{ marginTop: 24 }}
          >
            Send Code via {formData?.otp_meta?.altChannel?? "Email"}
          </p>

        </div>
      </Layout>
    </div>
  );
}

export default EnterCode;
