import React, { useState } from "react";
import {
  Beneficiaries,
  EditProfile,
  //Security,
  //UpgradeAccount,
} from "./MyAccount.helpers";

import UpdateTransactionPin from "./SubPages/UpdateTransactionPin/UpdateTransactionPin";
import SpPageTransition from "../../../sp-components/SpPagination/SpPagination";
//import RecurringPayments from "./SubPages/RecurringPayments";

const MyAccount = () => {
  const [activeTab, setActiveTab] = useState(0);
  const buttonLabels = [
    "Edit Profile",
    "Manage Beneficiaries",
    "Transaction PIN",
    /*    "Recurring Payments",
       "Security", */
    // "Upgrade Account",
  ];
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <SpPageTransition>
      <div className="account">
        <h3>My Account</h3>
        <div className="account-tab">
          {buttonLabels.map((label, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={activeTab === index ? "active" : ""}
            >
              {label}
            </button>
          ))}
        </div>
        {[
          <EditProfile />,
          <Beneficiaries />,
          <UpdateTransactionPin />

          /*  <RecurringPayments />,
           <Security />,
           <UpgradeAccount />, */
        ].map((items, index) => (
          <>{activeTab === index ? items : ""}</>
        ))}
      </div>
    </SpPageTransition>
  );
};

export default MyAccount;
