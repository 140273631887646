import { call, all, takeEvery, put } from 'redux-saga/effects';
import { loginRequest, refreshTokenRequest, registerRequest, authWithPinRequest } from './../../api/auth'; // API imports
import { login, refreshToken, register, authWithPin } from '../actions/auth'; // Promise actions
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { setAccessToken, translateErrorResponse } from '../../utils/utils';


// Saga to handle login
export function* handleLogin(action) {
  try {
    const response = yield call(loginRequest, action.payload);
    
    yield setAccessToken(response?.data?.data?.access_token);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}


// Saga to handle refresh token
export function* handleRefreshToken(action) {
  try {
    const response = yield call(refreshTokenRequest);
    
    yield setAccessToken(response?.data?.data?.access_token);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle validate login with pin
export function* handleAuthWithPin(action) {
  try {
    const response = yield call(authWithPinRequest, action.payload);
    setAccessToken(response?.data?.data?.access_token);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action
    
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle register
export function* handleRegister(action) {
  try {
    const response = yield call(registerRequest, action.payload);
    setAccessToken(response?.data?.data?.access_token);
    
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for auth actions
export default function* authSaga() {
  yield all([
    takeEvery(login.trigger, handleLogin), // Watch for login action
    takeEvery(refreshToken.trigger, handleRefreshToken), // Watch for refresh token action
    takeEvery(register.trigger, handleRegister), // Watch for register action
    takeEvery(authWithPin.trigger, handleAuthWithPin),
  ]);
}
