import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getModalReducer } from "../../redux/selectors/modal";
import { hideModal } from "../../redux/actions/modal";

const LceedataModal = ({ visible }) => {
  const {
    title,
    content,
    handleOk,
    values = {},
    functions = {},
  } = useSelector(getModalReducer, shallowEqual);

  const ModalComponent = content;

  const dispatch = useDispatch();

  const handleCancel = useCallback(() => dispatch(hideModal()), [dispatch]);

  return (
    <Modal
      title={title}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="modal__wrapper">
        {content && <ModalComponent {...values} {...functions} />}
      </div>
    </Modal>
  );
};

LceedataModal.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default LceedataModal;
