import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, requestAccount } from "../../../redux/actions/user";
import withUserData from "../../../HOC/withUserData";
import OtpInput from "react-otp-input";
import { redirectAfterDelay } from "../../../utils/utils";
import { useLocation } from "react-router-dom";
import { refreshToken } from "../../../redux/actions/auth";

const OtpModal = ({ onClick, data }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [countdownTimer, setCountdownTimer] = useState(0);
  const location = useLocation();

  const requestAccountState = useSelector((state) => state.user?.requestAccount);
  const {
    loading: isLoading = false,
    //data: loginData,
  } = requestAccountState || {};

  const otpLength = 6;

  const handleSubmit = useCallback(() => {
    if (otp?.length != otpLength) {
      message.error("Please check that the bank verification number is correct!");
      return;
    }

    dispatch(requestAccount({...data, ['otp']: otp})).then((res) => {
      message.success(res?.message?? 'Your virtual account request was successful.');
      dispatch(refreshToken());
      onClick();
    }).catch(err => {
      message.error(err);
    });

  }, [otpLength, otp, data, onClick, location]);

  const handleKeyUp = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }, []);


  useEffect(() => {
    if (countdownTimer > 0) {
      const timerId = setTimeout(() => setCountdownTimer(prev => prev - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [countdownTimer]);

  const resendCode = useCallback(() => {
    // Set resending to true to prevent duplicate requests
    setCountdownTimer(60);
    setOtp("");
    dispatch(requestAccount(data)).then((res) => {
      message.success(res?.message?? 'An OTP was sent to your phone.');
    }).catch(err => {
      message.error(err);
    });

  }, [dispatch, data]);


  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Create Virtual Account</h3>
        <p>Please enter the code we sent to you</p>
      </div>

      <div style={{
        marginTop: 42, 
        marginBottom: 48, 
        display: "flex", 
        alignItems: "center",
        justifyContent: "center",
      }}>
        <OtpInput
              isDisabled={isLoading}
              value={otp}
              onChange={setOtp}
              numInputs={otpLength}
              className="otp-input"
              separator={<div style={{ marginRight: 15 }} />}
              shouldAutoFocus
              isInputNum
              onKeyUp={handleKeyUp}
        />
      </div>

      <div className="modal_components__button" style={{paddingLeft: 32, paddingRight: 32}}>
        <SpButton fullWidth={true} loading={isLoading} onClick={handleSubmit}>Verify OTP</SpButton>
        <div style={{margin: 24}}>
        {countdownTimer === 0 ? (
            <p onClick={resendCode} className="resend text-align-center">Resend Code</p>
          ) : (
            <p className="resend text-align-center">Resending in {countdownTimer}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserData(OtpModal);
