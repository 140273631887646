import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useDispatch } from "react-redux";
import SpSummaryCard from "../../sp-components/SpSummaryCard/SpSummaryCard";
import SpButton from "../../sp-components/SpButton/SpButton";
import SpCopy from "../../sp-components/SpCopy/SpCopy";
import SpLoadingOverlay from "../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import withUserData from '../../HOC/withUserData';
import useIdleTimer from "../../hooks/useIdleTimer";
import { fetchTransactionDetails } from "../../redux/actions/transactions";
import { getNavPath } from "../../routes/routes.helpers";
import { NAV_ELECTRICITY } from "../../routes/routes.constant";
import { sleep } from "../../utils/utils";
import { formatAmount } from "../../helpers/numbers";
import SpPageTransition from "../../sp-components/SpPagination/SpPagination";

const { Title: CardTitle, Text: CardText } = SpSummaryCard;

const BankTransfer = ({ wallet = {} }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [idleTime, setIdleTime] = useState(2000);
  const [txData, setTxData] = useState(null);

  const {
    total_amount: amount = 0,
    virtual_account = {},
    transaction_reference: transactionReference,
  } = location?.state || {};

  const { bank_name: bankName, account_number: accountNumber } = virtual_account;
  const { main_balance: walletBalance = 0 } = wallet ?? {};

  const handlePaymentConfirmation = useCallback((actionType = "auto") => {
    const status = (txData?.payment_status ?? 'unpaid')?.toLowerCase();
    const requeriedStatuses = ['unpaid'];

    if (!isLoading && transactionReference && requeriedStatuses.includes(status)) {
      setIdleTime((prevIdleTime) => prevIdleTime + 2000);

      dispatch(fetchTransactionDetails({ transactionReference })).then((res) => {
        setTxData(res || {});

        if (res?.payment_status?.toLowerCase() === 'paid') {
          message.success("Payment received!");
          setIsLoading(true);

          sleep(1000).then(() => {
            setIsLoading(false);
            navigateToSuccess(transactionReference);
          });
        } else {
          handlePaymentFailure(actionType);
        }
      }).catch(() => {
        handlePaymentFailure(actionType); // Handle fetch failure
      });
    }
  }, [dispatch, txData, transactionReference, isLoading]);

  const handlePaymentFailure = (actionType = "auto") => {

    if (actionType !== "auto") {
      message.error("Payment hasn't been received yet. Please try again later.");
    }

    setIsLoading(false);
  };

  const navigateToSuccess = (transactionReference) => {
    navigate(`${getNavPath(NAV_ELECTRICITY)}/done`, {
      state: {
        transactionReference,
        paymentStatus: "success",
      },
      replace: true,
    });
  };

  // Use idle timer for automatic checks
  useIdleTimer(handlePaymentConfirmation, idleTime);

  return (
    <SpLoadingOverlay isLoading={isLoading}>
      <SpPageTransition>
        <div className="payment__wrapper" style={{ marginTop: "-1rem" }}>
          <h3 className="text-center">Complete payment</h3>
          <p className="text-center" style={{ marginTop: 24 }}>
            Complete your electricity payment by funding your wallet.
            Copy and recharge using the details below:
          </p>

          <div className="payment__card" style={{ marginTop: 20 }}>
            {walletBalance >= 0 && (
              <div>
                <CardTitle>Wallet Balance</CardTitle>
                <CardText>{formatAmount(walletBalance, "₦")}</CardText>
              </div>
            )}

            <div style={{ marginTop: 20 }}>
              <CardTitle>Amount to Pay</CardTitle>
              <div style={{ display: "flex", gap: 40 }}>
                <CardText>{formatAmount(amount, "₦")}</CardText>
                <SpCopy
                  text={amount.toString()}
                  successMessage="Amount copied!"
                  errorMessage="Failed to copy amount!"
                  label="Copy"
                  useButton={true}
                  size="small"
                  type="outline"
                >
                  Copy
                </SpCopy>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <CardTitle>Account Number</CardTitle>
              <div style={{ display: "flex", gap: 40 }}>
                <CardText>{accountNumber}</CardText>
                <SpCopy
                  text={accountNumber}
                  successMessage="Account number copied!"
                  errorMessage="Failed to copy account number!"
                  label="Copy"
                  useButton={true}
                  size="small"
                  type="outline"
                >
                  Copy
                </SpCopy>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <CardTitle>Bank Name</CardTitle>
              <CardText>{bankName}</CardText>
            </div>
          </div>

          <div className="text-center" style={{ marginTop: 20 }}>
            <SpButton
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                handlePaymentConfirmation("manual");
              }}
            >
              I have sent the money
            </SpButton>
          </div>
        </div>
      </SpPageTransition>
    </SpLoadingOverlay>
  );
};

export default withUserData(BankTransfer);
