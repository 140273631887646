import SpCardList from "../../../sp-components/SpCardList/SpCardList";
import { useDispatch, useSelector } from "react-redux";
import { fetchServicesByCategory } from "../../../redux/actions/service";
import { useCallback, useEffect, useState } from "react";
import SpInput from "../../../sp-components/SpInput/SpInput";
import SpMessage from "../../../sp-components/SpMessage/SpMessage";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { useNavigate } from "react-router-dom";
import { updateTransactionFormData } from "../../../redux/actions/transactions";
import SpLoadingOverlay from "../../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import Title from "antd/es/typography/Title";
import * as yup from 'yup';
import { useFormik } from "formik";
import withUserData from "../../../HOC/withUserData";
import { sleep } from "../../../utils/utils";
import SpPageTransition from "../../../sp-components/SpPagination/SpPagination";
import SpSelect from './../../../sp-components/SpSelect/SpSelect';
import useFetchPackages from "../../../hooks/useFetchPackages";
import useDebounce from "../../../hooks/useDebounce";


const validationSchema = yup.object({
  account: yup.string()
    .matches(/^\d+$/, 'Beneficiary phone number must be numeric')
    .required('Beneficiary phone number is required'),
  amount: yup.number()
    .required('Amount is required')
    .positive('Amount must be positive')
    .typeError('Amount must be numeric'),
  service: yup.object().required("Service type is required."),
  bundle_code: yup.string().required('Data plan is required.')
});

const InputDetails = ({ loggedInAsGuest = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const {
    transactionFormData: formData = { account: "", account_type: "", service_type: "", amount: 0 }
  } = useSelector((state) => state.transaction);

  const {
    isLoading: accountValidationLoading,
  } = useSelector((state) => state.accountValidation);

  const {
    data: services,
    loading: servicesLoading
  } = useSelector(state => state.service.services);

  useEffect(() => {
    dispatch(fetchServicesByCategory({ category_slug: "Internet" }));
  }, [dispatch]);

  const renderCardItem = (item, isActive) => (
    <img src={item.image_url} alt={item.code} className={`sp-card__icon ${isActive ? 'active' : ''}`} />
  );

  const renderCardFooter = (item, isActive) => (
    <div className={`sp-card__title ${isActive ? 'active' : ''}`}>{item.description}</div>
  );

  const getServiceCodeIndex = useCallback((services, selectedCode) => {
    return services.findIndex(service => service.code.toLowerCase() === selectedCode?.toLowerCase());
  }, []);

  const handleSubmit = useCallback((values) => {
    dispatch(updateTransactionFormData(values));

    setIsLoading(true);

    sleep(1000).then(() => {
      setIsLoading(false);
      navigate("payment-details");
    });

  }, [navigate, loggedInAsGuest]);

  const formik = useFormik({
    initialValues: formData,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const accountSearch = useDebounce(formik.values.account);

  const {
    isLoading: packageLoading,
    packages,
    setPackages
  } = useFetchPackages('internet', formik.values?.service?.code, accountSearch);

  const handleChange = useCallback((field, value) => {
    dispatch(updateTransactionFormData({ [field]: value }));
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true);
  }, [formik, dispatch]);

  const handleBlur = useCallback((field) => {
    formik.setFieldTouched(field, true);
  }, [formik]);
 
  const handlePackageSelected = (item) => {
    const {_code: bundle_code, _name: bundle_name, ...rest} = item || {};
    const values = {...formik.values, bundle_code, bundle_name, ...rest};
    
    formik.setValues(values);
    dispatch(updateTransactionFormData(values));
  }

  const formIsValid = formik.isValid && !isLoading;

  return (
    <SpLoadingOverlay isLoading={servicesLoading}>
      <SpPageTransition>
        <form onSubmit={formik.handleSubmit}>
          <Title level={4}>Internet / Data Top-up</Title>
          <p style={{ marginTop: 30 }}>Select Network</p>
          <SpCardList
            items={services}
            renderContent={renderCardItem}
            renderFooter={renderCardFooter}
            onSelected={item => handleChange("service", item)}
            defaultSelectedIndex={getServiceCodeIndex(services, formik.values.service?.code)}
            isLoading={accountValidationLoading}
          />

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Beneficiary Phone Number</p>
            <SpInput
              numericOnly
              value={formik.values.account}
              onChange={e => handleChange("account", e.target.value)}
              onBlur={() => handleBlur("account")}
            />

              <SpMessage type='danger' visible={formik.touched.account && formik.errors.account}>
                {formik.errors.account}
              </SpMessage>
          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Select a Bundle Plan</p>
            <SpSelect 
              onChange={handlePackageSelected} 
              showSearch={true} 
              filterOption={true} 
              options={packages} 
              loading={packageLoading} 
              defaultValue={formik.values?.description}
            />
              <SpMessage type='danger' visible={formik.errors.bundle_code}>
                {formik.errors.bundle_code}
              </SpMessage>
              
          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Amount</p>
            <div style={{ marginTop: 16 }}>
              <SpInput
                numericOnly
                value={formik.values.amount}
                readOnly={true}
                onChange={e => handleChange("amount", e.target.value)}
                onBlur={() => handleBlur("amount")}
              />
              <SpMessage type='danger' visible={formik.touched.amount && formik.errors.amount}>
                {formik.errors.amount}
              </SpMessage>
              
            </div>
          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <SpButton loading={isLoading} disabled={!formIsValid} htmlType="submit">Continue</SpButton>
          </div>
        </form>
      </SpPageTransition>
    </SpLoadingOverlay>
  );
};

export default withUserData(InputDetails);
