import SpCardList from "../../../sp-components/SpCardList/SpCardList";
import { useDispatch, useSelector } from "react-redux";
import { fetchServicesByCategory } from "../../../redux/actions/service";
import { useCallback, useEffect, useState } from "react";
import SpInputButtonGroup from "../../../sp-components/SpInputButtonGroup/SpInputButtonGroup";
import SpInput from "../../../sp-components/SpInput/SpInput";
import { formatAmount } from '../../../helpers/numbers';
import SpMessage from "../../../sp-components/SpMessage/SpMessage";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { useNavigate } from "react-router-dom";
import { updateTransactionFormData } from "../../../redux/actions/transactions";
import SpLoadingOverlay from "../../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import Title from "antd/es/typography/Title";
import * as yup from 'yup';
import { useFormik } from "formik";
import useDebounce from "../../../hooks/useDebounce";
import { validateAccount } from "../../../redux/actions/accountValidation";
import withUserData from "../../../HOC/withUserData";
import { sleep } from "../../../utils/utils";
import SpPageTransition from "../../../sp-components/SpPagination/SpPagination";
import SpSelect from './../../../sp-components/SpSelect/SpSelect';
import useFetchPackages from "../../../hooks/useFetchPackages";

const validationSchema = yup.object({
  account: yup.string()
    .matches(/^\d+$/, 'Decoder Number must be numeric')
    .required('Decoder Number is required'),
  name: yup.string()
    .required('Decode Number verification is required.'),
  amount: yup.number()
    .required('Amount is required')
    .positive('Amount must be positive')
    .typeError('Amount must be numeric'),
});

const InputDetails = ({ loggedInAsGuest = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const {
    transactionFormData: formData = { account: "", account_type: "", service_type: "", amount: 0 }
  } = useSelector((state) => state.transaction);

  const {
    isLoading: accountValidationLoading,
  } = useSelector((state) => state.accountValidation);

  const {
    data: services,
    loading: servicesLoading
  } = useSelector(state => state.service.services);

  useEffect(() => {
    dispatch(fetchServicesByCategory({ category_slug: "cabletv" }));
  }, [dispatch]);

  const renderCardItem = (item, isActive) => (
    <img src={item.image_url} alt={item.code} className={`sp-card__icon ${isActive ? 'active' : ''}`} />
  );

  const renderCardFooter = (item, isActive) => (
    <div className={`sp-card__title ${isActive ? 'active' : ''}`}>{item.description}</div>
  );

  const getServiceCodeIndex = useCallback((services, selectedCode) => {
    return services.findIndex(service => service.code.toLowerCase() === selectedCode?.toLowerCase());
  }, []);

  const handleSubmit = useCallback((values) => {
  
    dispatch(updateTransactionFormData(values));

    setIsLoading(true);

    sleep(1000).then(() => {
      setIsLoading(false);
      navigate("payment-details");
    });

  }, [navigate, loggedInAsGuest]);

  const formik = useFormik({
    initialValues: formData,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const accountSearch = useDebounce(formik.values.account);

  const {
    isLoading: packageLoading,
    packages,
    setPackages
  } = useFetchPackages(
    'cabletv', formik.values?.service?.code, accountSearch,
    (item) => {
      const {name: _name = "", code: _code = "", ...rest} = item || {};

      return {
        ...rest,
        _name,
        _code,
        key: _code,
        value: _code,
        label: `${formatAmount(item?.amount?? 0, '₦')} ---- ${item?.name}_${item?.description}`
      }
    }
  );

  const handleChange = useCallback((field, value) => {
    dispatch(updateTransactionFormData({ [field]: value }));
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true);
  }, [formik, dispatch]);

  const handleBlur = useCallback((field) => {
    formik.setFieldTouched(field, true);
  }, [formik]);

  useEffect(() => {
    if (accountSearch) {
      dispatch(validateAccount({
        service_type: 'cabletv',
        service_code: formik.values?.service?.code,
        account: formik.values.account,
      }))
        .then((res) => {
          formik.setFieldError('account', undefined);
          const data = { ...formik.values, ...res };
          formik.setValues(data);
        })
        .catch(err => formik.setFieldError('name', err || 'Validation failed. Please try again in 15mins!'));
    }
  }, [dispatch, accountSearch, formik.values.service?.code, formik.values.account_type]);

  const handlePackageSelected = (item) => {
    const {_code: package_code, _name: package_name, ...rest} = item || {};
    const values = {...formik.values, package_code, package_name, ...rest};
    
    formik.setValues(values);
    dispatch(updateTransactionFormData(values));
  }


  const formIsValid = formik.isValid && !isLoading && !packageLoading;

  return (
    <SpLoadingOverlay isLoading={servicesLoading}>
      <SpPageTransition>
        <form onSubmit={formik.handleSubmit}>
          <Title level={4}>Cable TV Subscription</Title>
          <p style={{ marginTop: 30 }}>Select Provider</p>
          <SpCardList
            items={services}
            renderContent={renderCardItem}
            renderFooter={renderCardFooter}
            onSelected={item => handleChange("service", item)}
            defaultSelectedIndex={getServiceCodeIndex(services, formik.values.service?.code)}
            isLoading={accountValidationLoading}
          />

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Decoder Number</p>
            <SpInput
              numericOnly
              value={formik.values.account}
              onChange={e => handleChange("account", e.target.value)}
              onBlur={() => handleBlur("account")}
              isLoading={accountValidationLoading}
            />
           <SpMessage type="success" visible={!!formik.values.name && !formik.errors.name}>
              {formik.values.name}
            </SpMessage>

            <SpMessage type="danger" visible={formik.errors.name && !accountValidationLoading}>
              {formik.errors.name}
            </SpMessage>

          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Select a Package</p>
            <SpSelect 
              onChange={handlePackageSelected} 
              showSearch={true} 
              filterOption={true} 
              options={packages} 
              loading={packageLoading} 
              defaultValue={formik.values?.package_code}
            />
              <SpMessage type='danger' visible={formik.errors.package_code}>
                {formik.errors.package_code}
              </SpMessage>
              
          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Amount</p>
          
            <div style={{ marginTop: 16 }}>
              <SpInput
                readOnly={true}
                numericOnly
                value={formik.values.amount}
                onChange={e => handleChange("amount", e.target.value)}
                onBlur={() => handleBlur("amount")}
              />
              <SpMessage type='danger' visible={formik.touched.amount && formik.errors.amount}>
                {formik.errors.amount}
              </SpMessage>
             
            </div>
          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <SpButton loading={isLoading} disabled={!formIsValid} htmlType="submit">Continue</SpButton>
          </div>
        </form>
      </SpPageTransition>
    </SpLoadingOverlay>
  );
};

export default withUserData(InputDetails);
