import React from 'react';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../utils/utils';


const withUserData = (WrappedComponent) => {
  return (props) => {
    const user = useSelector(state => state.user);
    const userData = user?.data?.user ?? {};

    const {
      id: userId,
      user_profile: userProfile = {},
      wallet = {},
      virtual_accounts: virtualAccounts = [],
      email = '',
      phone_number: phoneNumber = '',
      account_id: referralCode = ''
    } = userData?? {};

    const {
      first_name: firstName = '',
      last_name: lastName = '',
    } = userProfile?? {};

    const accessToken = getAccessToken();

   
    return (
      <WrappedComponent
        {...props}
        userData={{referralCode, firstName, lastName, displayName: firstName + ' ' + lastName, email, phoneNumber, userId }}
        isLoggedIn={accessToken?.length > 20}
        wallet={wallet}
        virtualAccounts={virtualAccounts}
      />
    );
  };
};

export default withUserData;