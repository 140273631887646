import { useCallback, useState } from "react";
import SpPaymentOptions from "../../sp-components/SpPaymentOptions/SpPaymentOptions";
import SpButton from './../../sp-components/SpButton/SpButton';
import { formatAmount } from './../../helpers/numbers';
import SpSummaryCard from "../../sp-components/SpSummaryCard/SpSummaryCard";
import { getFullHostName, sleep, translateServiceLabel } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpStatus from "../../sp-components/SpStatus/SpStatus";
import { initiateTransaction, resetTransactionInitState, updateTransactionFormData } from "../../redux/actions/transactions";
import SpLoadingOverlay from "../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import SpBackNav from "../../sp-components/SpBackNav/SpBackNav";
import { getNavPath } from "../../routes/routes.helpers";
import { NAV_AUTHORIZE_TRANSACTION, NAV_ELECTRICITY, NAV_PAYMENTS_BANK_TRANSFER } from "../../routes/routes.constant";
import { message } from "antd";
import withUserData from "../../HOC/withUserData";
import SpPageTransition from "../../sp-components/SpPagination/SpPagination";


const { Title: CardTitle, Text: CardText } = SpSummaryCard;


const PaymentDetails = ({ userData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const formData = useSelector((state) => state.transaction.transactionFormData);
    const transactionInitState = useSelector((state) => state.transaction.transactionInit);

    const {
        //error: txInitError,
        loading: txInitLoading,
    } = transactionInitState;

    const {
        account = "N/A",
        account_type: accountType = '',
        amount,
        //payment_method: paymentMethod,
        service = {},
    } = formData;

    const {
        image_url: iconUrl,
        transaction_fee: transactionFee = 0,
        service_category: serviceCategory = {},
    } = service;

    const {
        slug: type
    } = serviceCategory;

    //Handle Transaction Init response
    const handleTxInitResponse = useCallback((response) => {
        const paymentMethod = response?.payment_method?.toLowerCase();
        const state = { state: { transactionReference: response?.transaction_reference, ...response } }
        dispatch(resetTransactionInitState());

        switch (paymentMethod) {
            case 'card':
                window.location.href = response.payment_link;
                break;
            case 'bank_transfer':
                navigate(getNavPath(NAV_PAYMENTS_BANK_TRANSFER), state);
                break;
            default:
                navigate(`${getNavPath(NAV_ELECTRICITY)}/done`, state);
        }
    }, [dispatch]);

    const handleTransactionInitRequest = () => {
        const { service, ...rest } = formData ?? {};

        //alert(encryptPin('1234', getAccessToken()));

        const payload = {
            ...rest,
            ['service_type']: service?.service_category?.slug,
            ['service_code']: service?.code,
            ['save_beneficiary']: true,
        };

        const paymentMethod = payload['payment_method'] ?? "WALLET_MAIN";

        if (!paymentMethod) {
            message.error("Please select a payment method");
            return;
        }

        if (paymentMethod?.toLowerCase() === 'card') {
            payload['redirect_url'] = getFullHostName() + `/payment/confirmation`;
        }

        const paymentOptionsWithoutPinAuth = [
            'bank_transfer', 'card'
        ];

        if (!paymentOptionsWithoutPinAuth.includes(paymentMethod?.toLowerCase())) {
            payload.payment_method = payload?.payment_method?? 'WALLET_MAIN';
            
            navigate(getNavPath(NAV_AUTHORIZE_TRANSACTION), { state: payload });
            return;
        }

        dispatch(initiateTransaction(payload)).then((response) => {
            message.success("Transaction initiated successfully!");

            sleep(500).then(() => {
                handleTxInitResponse(response);
            });

        }).catch(err => {
            message.error(err);
        });
    };

    const handleChange = useCallback((field, value) => {
        dispatch(updateTransactionFormData({ ...formData, [field]: value }));
    }, []);


    const renderCardLeftColumn = useCallback(() => {
        return <>
            <div>
                <CardTitle>{translateServiceLabel(type, 'account')}</CardTitle>
                <CardText>{account}</CardText>
            </div>

            <div style={{ marginTop: 20 }}>
                <CardTitle>AMOUNT</CardTitle>
                <CardText>{formatAmount(amount, "₦")}</CardText>
            </div>
            <div style={{ marginTop: 20 }}>
                <CardTitle>TRANSACTION FEE</CardTitle>
                <CardText>{formatAmount(transactionFee, "₦")}</CardText>
            </div>
        </>
    }, []);

    const renderCardRightColumn = useCallback(() => {
        return <div
            style={{
                marginTop: 4,
            }}>
            {
                iconUrl &&
                <img
                    src={iconUrl}
                    alt={service}
                    style={{ width: 72, height: 72, borderRadius: "50%" }}
                />
            }
            {
                accountType && <div style={{ marginTop: 80 }}>
                    <SpStatus type="primary">{accountType}</SpStatus>
                </div>
            }
        </div>
    }, []);



    return (
        <SpLoadingOverlay isLoading={isLoading}>
            <SpPageTransition>
                <SpBackNav title="Confirm Details" url={`/${type}`} />
                <div style={{ maxWidth: "100%", marginTop: 32 }}>
                    <SpSummaryCard
                        renderLeftColumn={renderCardLeftColumn}
                        renderRightColumn={renderCardRightColumn}
                    />
                </div>
                {/* <div style={{ marginTop: 40 }}>
                    <SpPaymentOptions
                        onSelected={(index, item) => {
                            handleChange("payment_method", item?.type);
                        }}
                    />
                </div> */}
                <div style={{ marginTop: 40 }}>
                    <SpButton loading={txInitLoading} onClick={handleTransactionInitRequest}>Pay {formatAmount(formData?.total_amount ?? 0, "₦")}</SpButton>
                </div>
            </SpPageTransition>
        </SpLoadingOverlay>
    );
};


export default withUserData(PaymentDetails);