import { Drawer, Menu } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardInfo from "../../pages/Dashboard/Dashboard.info";
import LceedataButton from "../../ui/Button/Button";
import useAccountBalance from "../../hooks/useAccountBalance";
import { logout } from "../../redux/actions/user";
import withUserData from "../../HOC/withUserData";
import { fetchServiceCategories } from "../../redux/actions/service";
import { resetTransactionFormData } from "../../redux/actions/transactions";
import { redirectAfterDelay } from "../../utils/utils";
import logo from "../../assets/logo/Logo.png";

const LceedataDrawer = ({ showDrawer, onClose, userData = {}, isLoggedIn }) => {
  const [open, setOpen] = useState(false);
  const { firstName } = userData;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    data: categories,
  } = useSelector(state => state.service.categories);

  useEffect(() => setOpen(showDrawer), [showDrawer]);
  const { accountDetails, accountBalance } = useAccountBalance();

  const pathName =  location.pathname;
  const pathParts = pathName.split('/').filter(Boolean);

  const [activeKey, setActiveKey] = useState(pathParts[0] || '');

  useEffect(() => {
    dispatch(fetchServiceCategories());
  }, []);

  const handleClick = useCallback(
    (e) => {
      dispatch(resetTransactionFormData());
      onClose();

      const url = '/' + e.key;

      if (isLoggedIn) {
        setActiveKey(e.key);
        navigate(url);
      }
      else {
        navigate('/login', {}, url);
      }
    },
    [navigate, location, onClose, isLoggedIn]
  );

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);


  return (
    <Drawer
      title={
        <div style={{
          display: "flex",
          flexDirection: 'column',
          rowGap: 32
        }}>
          <img
            className="layout__logo"
            src={logo}
            alt=""
            onClick={() => redirectAfterDelay('/')}
            style={{alignSelf: 'center'}}
          />

          <div>
            <ion-icon name="close-outline"></ion-icon>
            <DashboardInfo
              balance={accountBalance}
              username={firstName}
              bankName={accountDetails?.bank_name}
              accountNumber={accountDetails?.account_number}
            />
          </div>
        </div>
      }
      footer={
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 64,
        }}>
          {isLoggedIn && <LceedataButton
            label="Logout"
            customClass="layout__button"
            shape="round"
            size="sm"
            buttonType="cancel-light"
            onClick={handleLogout}
          />}
        </div>
      }
      contentWrapperStyle={{ width: "75%" }}
      placement="left"
      closable={false}
      onClose={onClose}
      open={open}
      key="left"
      className="sp-drawer"
      headerStyle={{ padding: 30 }}
    >
      <Menu
        mode="inline"
        className="dashboard__menu"
        selectedKeys={[activeKey]}
      >
        {categories.map(({ slug: index, name: label, image_url: imageUrl, status }) => (
          <Menu.Item
            className="dashboard__menu_item"
            icon={<img src={imageUrl} className={`service-img-icon ${activeKey === index ? 'active' : ''}`} />}
            key={index}
            onClick={status === "active" ? handleClick : null}
          >
            <Fragment>
              {label}
              {activeKey === index && (
                <ion-icon name="arrow-forward-outline"></ion-icon>
              )}
            </Fragment>
          </Menu.Item>
        ))}
        <div className="justify-content-center mt-lg">


          {/* {!isLoggedIn && <div style={{display: 'flex', justifyContent: 'space-between', columnGap: 4}}>
          <LceedataButton
            label="Login"
            shape="round"
            onClick={() => redirectAfterDelay('/login')}
          />
          <LceedataButton
            label="Register"
            shape="round"
            onClick={() => redirectAfterDelay('/signup')}
          />
          </div>} */}
        </div>
      </Menu>
    </Drawer>
  );
};
export default withUserData(LceedataDrawer);
