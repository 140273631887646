import Spinner from '../../../ui/Spinner/Spinner';
import transactionInitImage from '../../../assets/images/transaction_initiated.png';
import LceedataButton from "../../../ui/Button/Button";
import useResolveTransaction from '../../../hooks/useResolveTransaction';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const TransactionInitiated = ({transactionReference, setData}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        data: resolvedData, 
        isLoading: txResolving, 
        resolveTransaction
    } = useResolveTransaction(transactionReference);

    useEffect(() => {
        if (resolvedData && !['initiated'].includes(resolvedData?.status?.toLowerCase())) {
            setData(resolvedData);
        }
    }, [resolvedData, navigate]);

    return (
        <div style={{
            backgroundColor: '#fff',
            padding: "1.2rem 2rem",
            borderRadius: 12,
            marginTop: 16,
        }}>

            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: '33vh',
                textAlign: 'center',
                padding: "2rem 2rem",

            }}>

                <div style={{ marginBottom: 24 }}>
                    <img src={transactionInitImage} alt='' style={{
                        maxWidth: "205px"
                    }} />
                </div>
                <h4 style={{
                    fontSize: 20,
                }}>Transaction initiated</h4>
                <p style={{
                    color: "#696969",
                    maxWidth: "320px",
                    fontSize: 14,
                }}>
                    Your transaction with reference <span style={{ fontWeight: 600, color: "#696969" }}>{transactionReference}</span> is currently processing.</p>

                <div style={{ marginTop: "32px" }}>
                    <Spinner size="large" />
                </div>

                <div style={{ marginTop: "32px"}}>
                <p style={{
                    color: "#696969",
                    maxWidth: "320px",
                    fontSize: 14,
                }}>
                    Taking too long?
                    </p>
                    <LceedataButton
                        label="Report Issue"
                        buttonType="bordered"
                        loading={txResolving}
                        onClick={resolveTransaction}
                    />
                </div>
            </div>
        </div>
    );
};

export default TransactionInitiated;
