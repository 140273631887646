import React, { useCallback, useEffect, useState } from "react";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import LceedataButton from "../../../ui/Button/Button";
import { Form, Input } from "antd";
import ElectricityIcon from "../../../assets/svgs/menu-icons/electricity";
import InternetIcon from "../../../assets/svgs/menu-icons/internet";
import TransferIcon from "../../../assets/svgs/menu-icons/transfer";
import CableIcon from "../../../assets/svgs/menu-icons/cable";
import AirtimeIcon from "../../../assets/svgs/menu-icons/airtime";
import ExamsIcon from "../../../assets/svgs/menu-icons/exams";
import Icon from "../../../assets/Icon";
import { useDispatch } from "react-redux";

import { showModal } from "../../../redux/actions/modal";
import {
  ADDRESS_VERIFICATION,
  DELETE_BENEFICIARY,
  EDIT_BENEFICIARY,
  VERIFY_BVN,
  VERIFY_ID_CARD,
} from "../../../components/Modal/ModalComponents";
import {
  editTransactionPin,
  getKycLevel,
  getUserBeneficiaryTransactions,
  updateUserRequest,
} from "../../../api/user";
import LceedataInput from "../../../ui/Input/Input";
import { EmptyState } from "../heplers";
//import { setCurrentBeneficiary } from "../../../redux/actions/user";
import useAccountBalance from "../../../hooks/useAccountBalance";
import withUserData from "../../../HOC/withUserData";
import { getNavPath } from './../../../routes/routes.helpers';
import { NAV_SIGN_UP } from "../../../routes/routes.constant";
import { getFullHostName } from "../../../utils/utils";

const EditProfileComp = ({userData}) => {
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const { accountDetails } = useAccountBalance();

  const { first_name, last_name, email, userId } = accountDetails || {};

  const { referralCode } = userData || {};

  const updatedProfileData = {
    firstName: first_name,
    lastName: last_name,
    email: newEmail,
  };

  const handleEmailInput = (e) => {
    const { value } = e.target;
    setNewEmail(value);
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const response = await updateUserRequest(updatedProfileData);
      if (response.status === 200) {
        setLoading(false);
        message.success("Profile Updated!!");
      }
    } catch (error) {
      setLoading(false);
      //message.error(error?.message || "Couldn't update profile");
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(getFullHostName() + getNavPath(NAV_SIGN_UP + '?referral-code=' + referralCode));
    message.success("Copied to clipboard");
  };

  useEffect(() => {
    setNewEmail(email);
  }, [email]);

  return (
    <>
      <div className="account__edit-profile">
        <div>
          <span className="span-container">
            <p>First Name</p>
            <input
              className="profile-input"
              type="text"
              value={userData?.firstName?? 'N/A'}
              disabled
            />
          </span>
          <span className="span-container">
            <p>Last Name</p>
            <input
              className="profile-input"
              type={"text"}
              value={userData?.lastName?? 'N/A'}
              disabled
            />
          </span>
        </div>
        <div>
          <p>Phone Number</p>
          <input
            className="profile-input phone-number"
            type={"number"}
            value={userData?.phoneNumber}
            disabled
          />
        </div>

        <div>
          <LceedataInput
            label={"Referral code"}
            value={referralCode?? 'N/A'}
            contentRight={<Icon icon="copy1" onClick={handleCopyClick} />}
            wrapperClass={"ref-code-input"}
          />  
        </div>

        <div>
          <p>
            Email address {/* <i>(optional)</i> */}
          </p>
          <input
            className="profile-input email-input"
            type={"email"}
            value={userData?.email}
            onInput={handleEmailInput}
            disabled
          />
        </div>
        <div>
          <LceedataButton
            label={"Save Changes"}
            onClick={handleSaveChanges}
            loading={loading}
            disabled={true}
          />
        </div>
      </div>
    </>
  );
};

export const EditProfile = withUserData(EditProfileComp);

export const Beneficiaries = () => {
  const [fetching, setFetching] = useState(true);
  const [beneficiaryTransactions, setBeneficiaryTransaction] = useState([]);
  const [displayedBeneficiaries, setDisplayedBeneficiaries] = useState(6);
  const [refetch, setRefetch] = useState("");

  const dispatch = useDispatch();

  const loader = useCallback(() => {
    const antIcon = <LoadingOutlined className="loader-icon" spin />;
    return (
      <div className="justify-content-center">
        <Spin indicator={antIcon} />
      </div>
    );
  }, []);

  useEffect(() => {
    const fetchBeneficiaryTransactions = async () => {
      try {
        const transactions = (await getUserBeneficiaryTransactions()).data.data;
        setBeneficiaryTransaction(transactions);
        setFetching(false);
      } catch (error) {
        message.error("error fetching data");
        setFetching(false);
      }
    };

    fetchBeneficiaryTransactions();
  }, [refetch]);

  const handleLoadMore = () => {
    if (displayedBeneficiaries + 6 <= beneficiaryTransactions.length) {
      setDisplayedBeneficiaries((prevCount) => prevCount + 6);
    } else if (displayedBeneficiaries < beneficiaryTransactions.length) {
      setDisplayedBeneficiaries(beneficiaryTransactions.length);
    } else {
      setDisplayedBeneficiaries(6);
    }
  };

  const getTransactionIcon = (utilityType) => {
    switch (utilityType) {
      case "electricity":
        return <ElectricityIcon className="transaction-type" />;
      case "internet":
        return <InternetIcon className="transaction-type" />;
      case "transfer":
        return <TransferIcon className="transaction-type" />;
      case "cable":
        return <CableIcon className="transaction-type" />;
      case "airtime":
        return <AirtimeIcon className="transaction-type" />;
      case "exams":
        return <ExamsIcon className="transaction-type" />;
      default:
        return null;
    }
  };

  
  const handleEditClick = useCallback((beneficiary) => {
    /* dispatch(
      setCurrentBeneficiary({
        name: beneficiary?.beneficiaryName,
        id: beneficiary?._id,
      })
    ); */
    dispatch(
      showModal({
        modalType: EDIT_BENEFICIARY,
        functions: {
          refetch: (value) => {
            setRefetch(value);
          },
        },
      })
    );
  }, []);

  const handleDeleteClick = useCallback((beneficiary) => {
   /*  dispatch(
      setCurrentBeneficiary({
        name: beneficiary?.beneficiaryName,
        id: beneficiary?._id,
      })
    ); */
    dispatch(
      showModal({
        modalType: DELETE_BENEFICIARY,
        functions: {
          refetch: (value) => {
            setRefetch(value);
          },
        },
      })
    );
  }, []);

  return (
    <div className="account__beneficiaries">
      {fetching ? (
        loader()
      ) : (
        <>
          {beneficiaryTransactions?.length === 0 ? (
            <EmptyState />
          ) : (
            <>
              <div className="beneficiaries_card">
                {beneficiaryTransactions
                  ?.slice(0, displayedBeneficiaries)
                  .map((beneficiary, i) => (
                    <div
                      className={`beneficiaries_card-items ${
                        i !== 0 ? "with-border-top" : ""
                      }`}
                      key={i}
                    >
                      <div className="beneficiary-label">
                        <span className="beneficiary-label-icon">
                          {getTransactionIcon(beneficiary.utilityType)}
                        </span>
                        <span className="beneficiary-label-amount">
                          <p className="amount">
                            {beneficiary.beneficiaryName}
                          </p>
                          <span>
                            <p className="beneficiary-id">
                              {beneficiary.account}
                            </p>
                            <p>.</p>
                            <p className="payment-method">
                              {beneficiary?.service?.name?.toUpperCase()}
                            </p>
                          </span>
                        </span>
                      </div>
                      <div className="beneficiary-status">
                       {/*  <button
                          className="beneficiary-status_edit"
                          onClick={() => handleEditClick(beneficiary)}
                        >
                          <Icon icon="edit" width={12} height={12} /> Edit
                        </button> */}
                        <button
                          className="beneficiary-status_delete"
                          onClick={() => handleDeleteClick(beneficiary)}
                        >
                          <Icon icon="delete" width={16} height={16} />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>

              {beneficiaryTransactions?.length > 6 && (
                <LceedataButton
                  label={
                    displayedBeneficiaries >= beneficiaryTransactions.length
                      ? "Load Less Beneficiaries"
                      : "Load More Beneficiaries"
                  }
                  buttonType="bordered"
                  customClass="loadmore-btn"
                  onClick={handleLoadMore}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export const Security = () => {
  const [currentPin, setCurrentPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePinInput = (e) => {
    setCurrentPin(e.target.value);
  };

  const handleNewPinInput = (e) => {
    setNewPin(e.target.value);
  };

  const handleConfirmPinInput = (e) => {
    setConfirmNewPin(e.target.value);
    if (newPin !== e.target.value) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleChangePin = async () => {
    setLoading(true);
    if (error) {
      return;
    }
    try {
      const updatedPinInfo = {
        newPin: newPin,
        initialPin: currentPin,
      };

      const response = await editTransactionPin(updatedPinInfo);
      if (response.status === 200) {
        setLoading(false);
        message.success("Pin Changed Successfully!!");
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Something went wrong");
    }
  };
  const handleKeyDown = (event) => {
    if (
      !(
        event.key === "Backspace" ||
        (event.ctrlKey && (event.key === "v" || event.key === "c")) ||
        /[0-9]/.test(event.key)
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div>
        <Form name="basic" layout="vertical" autoComplete="off">
          <Form.Item
            label={<label>Current PIN</label>}
            name="Current pin"
            className="lcee-security__inputWrapper"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input.Password
              className="lcee-security__password"
              placeholder="********"
              onChange={handlePinInput}
              value={currentPin}
              maxLength={4}
              onKeyDown={(event) => handleKeyDown(event)}
            />
          </Form.Item>
          <Form.Item
            label={<label>New PIN</label>}
            name="New pin"
            className="lcee-security__inputWrapper"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input.Password
              className="lcee-security__password"
              placeholder="********"
              onChange={handleNewPinInput}
              value={newPin}
              maxLength={4}
              onKeyDown={(event) => handleKeyDown(event)}
            />
          </Form.Item>
          <Form.Item
            label={<label>Confirm PIN</label>}
            name="Confirm pin"
            className="lcee-security__inputWrapper"
            rules={[
              { required: true, message: "Please confirm your new PIN" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPin") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The New PIN and Confirm PIN do not match"
                  );
                },
              }),
            ]}
            hasFeedback
            validateStatus={error ? "error" : ""}
            help={error ? "The New PIN and Confirm PIN do not match" : ""}
          >
            <Input.Password
              className="lcee-security__password"
              placeholder="********"
              onChange={handleConfirmPinInput}
              value={confirmNewPin}
              maxLength={4}
              onKeyDown={(event) => handleKeyDown(event)}
            />
          </Form.Item>
          <Form.Item>
            <LceedataButton
              label={"Access account"}
              customClass="lcee-security__button"
              shape="rounded"
              buttonType="primary"
              onClick={handleChangePin}
              disabled={error}
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export const UpgradeAccount = () => {
  const [refetch, setRefetch] = useState("");
  const [kycLevel, setKycLevel] = useState([]);

  useEffect(() => {
    const fetchUserKycLevel = async () => {
      try {
        const kyc = await getKycLevel();
        setKycLevel(kyc?.data?.data);
        console.log(kyc?.data?.data[0]);
      } catch (error) {
        console.log(`Error fetching KYC level: ${error}`);
      }
    };

    fetchUserKycLevel();
  }, [refetch]);

  const dispatch = useDispatch();
  const kycLevelsData = [
    {
      level: "Level 1",
      amount: "₦ 50,000",
      limit: "Daily Limit",
      buttonText: "Get Started",
      details: [
        { icon: "checkmark", text: "BVN required" },
        { icon: "checkmark", text: "₦ 50 bonus" },
      ],
    },
    {
      level: "Level 2",
      amount: "₦ 300,000",
      limit: "Daily Limit",
      buttonText: "Get Started",
      details: [
        { icon: "checkmark", text: "ID Card required" },
        { icon: "checkmark", text: "₦ 100 bonus" },
      ],
    },
    {
      level: "Level 3",
      amount: "₦ 1,000,000",
      limit: "Daily Limit",
      buttonText: "Get Started",
      details: [
        { icon: "checkmark", text: "Address required" },
        { icon: "checkmark", text: "₦ 150 bonus" },
      ],
    },
  ];

  const handleGetStarted = useCallback(
    (index) => {
      const modalTypes = {
        0: VERIFY_BVN,
        1: VERIFY_ID_CARD,
        2: ADDRESS_VERIFICATION,
      };

      const selectedModalType = modalTypes[index];

      if (selectedModalType) {
        dispatch(
          showModal({
            modalType: selectedModalType,
            functions: {
              currentDisplay: (value) => {
                setRefetch(value);
              },
            },
          })
        );
      }
    },
    [dispatch, refetch]
  );

  return (
    <div className="account__upgrade-account">
      <div className="upgrade-account_message">
        Please upgrade your account to be able to use Lceedata. We are following
        compliance regulations to make sure you are not using your Lceedata wallet
        for fraudulent purposes.
      </div>
      <div className="upgrade-account_levels-container">
        {kycLevelsData.map((data, index) => (
          <div
            className={`levels ${
              kycLevel[index]?.kycLevel >= index + 1 ? "active" : ""
            }`}
            key={index}
          >
            <span className="levels-first">
              {data.level}{" "}
              {kycLevel[index]?.kycLevel >= index + 1 ? (
                <Icon icon="check-circle" width={20} height={20} />
              ) : (
                ""
              )}
            </span>
            <span className="levels-second">
              <b>{data.amount}</b> {data.limit}
            </span>
            <button
              className={`levels-button ${
                index !== 0 && kycLevel[index - 1] === undefined
                  ? "opacity"
                  : kycLevel[index]?.kycLevel >= index + 1
                  ? "opacity"
                  : ""
              }`}
              onClick={() => handleGetStarted(index)}
              disabled={
                index !== 0 && kycLevel[index - 1] === undefined
                  ? true
                  : kycLevel[index]?.kycLevel >= index + 1
                  ? true
                  : false
              }
            >
              {data.buttonText} <Icon icon="arrow-right" />
            </button>
            <ul className="levels-last">
              <p className="levels-last_paragraph">Details</p>
              {data.details.map((detail, i) => (
                <li className="levels-last_list" key={i}>
                  <Icon icon={detail.icon} />
                  {detail.text}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export const Payments = () => {
  return (
    <div>
      <h3>Payments</h3>
    </div>
  );
};
