import { useEffect, useState } from 'react';
import { fetchPackagesRequest } from '../api/services';

const useFetchPackages = (serviceType, serviceCode, account, itemBuilder) => {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPackages = async () => {
      setIsLoading(true);
      try {
        const response = await fetchPackagesRequest({
          service_type: serviceType,
          service_code: serviceCode,
          account,
        });

        // Format response data to match { key, label, value }
        const formattedPackages = (response?.data?.data ?? []).map(item => {
           
            
            if (typeof itemBuilder === "function") {
                return itemBuilder(item);
            }

            const {name: _name = "", code: _code = "", ...rest} = item || {};

            return {
                ...rest,
                _name,
                _code,
                key: rest?.description,
                label: rest?.description,
                value: rest?.description,
            }
        });

        setPackages(formattedPackages);
      } catch (error) {
        console.error('Error fetching packages:', error);
        setPackages([]);
      } finally {
        setIsLoading(false);
      }
    };

    // Trigger fetch only if all necessary conditions are met
    if (serviceCode && account) {
      fetchPackages();
    }
  }, [serviceType, serviceCode, account, fetchPackagesRequest]);

  return { isLoading, packages, setPackages };
};

export default useFetchPackages;
