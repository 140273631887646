import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [historyCount, setHistoryCount] = useState(0);
  const [firstUrl, setFirstUrl] = useState('');
  const currentUrl = location.pathname;

  const isFirstVisit = useRef(true); // To track if it's the first navigation

  // Effect to run on URL changes
  useEffect(() => {
    if (isFirstVisit.current) {
      // Store the first URL on the initial render
      setFirstUrl(location.pathname);
      isFirstVisit.current = false;
    }

    // Increment history count on each navigation
    setHistoryCount((prevCount) => prevCount + 1);
  }, [location]);

  // Custom navigate function with optional redirect URL
  const customNavigate = (path, options = {}, redirectUrl = null) => {
    if (redirectUrl) {
      // Add leading slash if not present
      const formattedRedirectUrl = redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`;
      localStorage.setItem('redirectUrl', formattedRedirectUrl); // Store formatted redirect URL in localStorage
    }
    navigate(path, options); // Core responsibility: useNavigate functionality
  };

  // Function to retrieve the redirect URL from localStorage
  const getRedirectUrl = () => localStorage.getItem('redirectUrl');

  // Function to clear the redirect URL from localStorage
  const clearRedirect = () => localStorage.removeItem('redirectUrl');

  return {
    navigate: customNavigate,  // The navigate function to be used like useNavigate
    historyCount,              // Number of times the URL has changed
    firstUrl,                  // The first URL visited
    currentUrl,                // The current URL
    getRedirectUrl,            // Function to retrieve redirect URL
    clearRedirect,             // Function to clear the redirect URL
  };
};

export default useCustomNavigate;
