import React from 'react';
import GetStarted from "../../components/Modal/ModalComponents/GetStarted";
import TransactionPin from './../../ui/TransactionPin/TransactionPin';
import { useModal } from '../../context/ModalContext';
import RequestVirtualAccountModal from '../../components/Modal/ModalComponents/RequestVirtualAccountModal';
import OtpModal from '../../components/Modal/ModalComponents/OtpModal';
import PinAuthModal from '../../components/Modal/ModalComponents/PinAuthModal';

const useSpModal = () => {
  const { showModal } = useModal();

  const getStarted = (props = {}) => {
    showModal(GetStarted, props);
  };

  const showTransactionPin = (props = {}) => {
    showModal(TransactionPin, props);
  };

  const requestVirtualAccount = (props = {}) => {
    showModal(RequestVirtualAccountModal, props);
  };

  const showOtp = (props = {}) => {
    showModal(OtpModal, props);
  };

  const showPinAuth = (props = {}) => {
    showModal(PinAuthModal, props);
  };

  return {
    getStarted,
    showTransactionPin,
    requestVirtualAccount,
    showOtp,
    showPinAuth
  };
};

export default useSpModal;
