import React from "react";
import LceedataButton from "../../ui/Button/Button";
import ComingSoonImg from "../../assets/svgs/coming-soon.svg";

function ComingSoon() {
  return (
    <div className="coming-soon main-layout">
      <div className="content">
        <img src={ComingSoonImg} alt="coming soon" />
        <br />
        <br />
        <h3>We’re still building</h3>
        <div className="text">
          <div>
            Hey there, we’re still tightening the screws on this feature.
          </div>
          <div>Please check in later!</div>
        </div>
        <br />
        <LceedataButton
          label="Go back home"
          onClick={() => (window.location.href = "/")}
          buttonType="bordered"
        />
      </div>
    </div>
  );
}

export default ComingSoon;
