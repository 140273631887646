
import { BankCardIcon, BankTransferIcon, WalletIcon } from './../components/Svgs';

export const paymentOptionsForGuest = [
    {
        label: "Pay with Bank Transfer",
        icon: <BankTransferIcon />,
        disabled: false,
        type: "BANK_TRANSFER",
    },
    {
        label: "Pay with Debit Card",
        icon: <BankCardIcon />,
        disabled: false,
        type: "CARD"
    },
];

export const paymentOptions = [
    {
        label: "Pay with Wallet",
        icon: <WalletIcon />,
        disabled: false,
        type: "WALLET_MAIN",
    },
    ...paymentOptionsForGuest
];


