import React, { useCallback, useEffect, useState } from "react";
import _times from "lodash/times";
import PinInput from "../../ui/PinInput/PinInput";

function TransactionPin({ onComplete, initialPin = "", reset }) {
  const [pin, setPin] = useState(initialPin);

  const handlePinChange = useCallback(
    (value) => {
      if (pin.length < 4) {
        setPin((prev) => prev + value);
      }
      if (pin.length === 3) {
        onComplete(pin + value);
      }
    },
    [pin, onComplete]
  );

  // Reset pin when the reset prop changes
  useEffect(() => {
    if (reset) {
      setPin("");
    }
  }, [reset]);

  const handleDelete = useCallback(() => {
    setPin(pin.slice(0, pin.length - 1));
  }, [pin]);

  const renderInput = useCallback(
    (value) => {
      if (!value) {
        return <div className="transaction-pin__empty"></div>;
      }
      if (value === "delete") {
        return (
          <div
            className="transaction-pin__empty transaction-pin__delete"
            onClick={handleDelete}
            key={value}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7916 13.5309C17.0846 13.8239 17.0846 14.2989 16.7916 14.5919C16.6456 14.7379 16.4536 14.8109 16.2616 14.8109C16.0696 14.8109 15.8776 14.7379 15.7306 14.5919L14.2056 13.0669L12.6806 14.5919C12.3876 14.8849 11.9136 14.8849 11.6206 14.5919C11.3276 14.2989 11.3276 13.8239 11.6206 13.5309L13.1456 12.0069L11.6206 10.4809C11.3276 10.1879 11.3276 9.71394 11.6206 9.42094C11.9136 9.12794 12.3876 9.12794 12.6806 9.42094L14.2056 10.9449L15.7306 9.42094C16.0236 9.12794 16.4986 9.12794 16.7916 9.42094C17.0846 9.71394 17.0846 10.1879 16.7916 10.4809L15.2666 12.0069L16.7916 13.5309ZM18.3276 4.71094H9.20159C8.45459 4.71094 7.74859 5.02794 7.26559 5.58194L3.13459 10.3109C2.29059 11.2739 2.29059 12.7269 3.13359 13.6899L7.26559 18.4199C7.74859 18.9729 8.45459 19.2909 9.20159 19.2909H18.3276C20.0756 19.2909 21.4986 17.8689 21.4986 16.1209V7.88094C21.4986 6.13294 20.0756 4.71094 18.3276 4.71094Z"
                fill="black"
              />
            </svg>
          </div>
        );
      }
      return <PinInput key={value} value={value} onChange={handlePinChange} />;
    },
    [handlePinChange, handleDelete]
  );

  return (
    <div className="prevent-select">
      <div className="justify-content-center">
        {_times(4, (index) => {
          const item = pin.substr(index, 1);
          return (
            <span
              className={!item ? "pin-secret" : "pin-secret__item"}
              key={index}
            >
              {String.fromCharCode(item ? 0x2022 : 0x25e6)}
            </span>
          );
        })}
      </div>

      <br />
      <div className="transaction-pin">
        {_times(3, (i) => (
          <div className="justify-space-between" key={`${i}-row`}>
            {_times(3, (j) => renderInput(3 * i + j + 1))}
          </div>
        ))}

        <div className="justify-space-between">
          {renderInput(false)}
          {renderInput("0")}
          {renderInput("delete")}
        </div>
      </div>
    </div>
  );
}

export default TransactionPin;
