import React, { useState } from "react";
import LceedataButton from "../../../../ui/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentBeneficiary } from "../../../../redux/selectors/user";
import { hideModal } from "../../../../redux/actions/modal";
import { deleteBeneficiary } from "../../../../api/user";
import { message } from "antd";

const DeleteBeneficiary = ({ refetch }) => {
  const currentBeneficiary = useSelector(getCurrentBeneficiary);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteBeneficiary(currentBeneficiary?.id);
      if (response.status === 200) {
        refetch(Date.now());
        setLoading(false);
        message.success("Beneficiary Deleted!!");
        setTimeout(() => {
          dispatch(hideModal());
        }, 100);
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Something went wrong");
    }
  };

  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Delete Beneficiary</h3>
        <p style={{ marginTop: "1em" }}>
          Are you sure you want to delete this beneficiary? This action is not
          reversible.
        </p>
      </div>

      <div className="modal_components__button double_buttons">
        <LceedataButton
          label="Cancel"
          customClass="half-width"
          onClick={() => dispatch(hideModal())}
        />
        <LceedataButton
          label="Delete"
          buttonType="cancel-dark"
          customClass="half-width"
          onClick={handleDelete}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default DeleteBeneficiary;
