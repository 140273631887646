import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SpOptionButton from './SpOptionButton';

const SpOptionButtonGroup = ({isLoading = false, className, buttons, multiple = false, onSelected, direction = "row", defaultActiveIndexes = [] }) => {
    const [activeIndexes, setActiveIndexes] = useState([]);

    useEffect(() => {
        setActiveIndexes(defaultActiveIndexes);
    }, [defaultActiveIndexes]);

    const handleButtonClick = (index) => {
        if (multiple) {
            setActiveIndexes((prev) =>
                prev.includes(index)
                    ? prev.filter((i) => i !== index)
                    : [...prev, index]
            );
        } else {
            setActiveIndexes([index]);
        }

        if (typeof onSelected === 'function') {
            if (!multiple) {
                onSelected(buttons[index], index);
            }
        }
    };

    return (
        <div className={`sp-option-button-group sp-option-button-group__${direction} ${className}`}>
            {buttons.map((button, index) => (
                <SpOptionButton
                    key={index}
                    label={button.label}
                    icon={button.icon}
                    isActive={activeIndexes.includes(index)}
                    disabled={isLoading || button.disabled}
                    onClick={() => handleButtonClick(index)}
                />
            ))}
        </div>
    );
};

SpOptionButtonGroup.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            icon: PropTypes.node,
            disabled: PropTypes.bool
        })
    ).isRequired,
    multiple: PropTypes.bool,
    onSelected: PropTypes.func,
    direction: PropTypes.oneOf(["row", "column"]),
    defaultActiveIndexes: PropTypes.arrayOf(PropTypes.number),
    className: PropTypes.string,
};

SpOptionButtonGroup.defaultProps = {
    disabled: false,
    icon: null,
    defaultActiveIndexes: [],
    className: ''
};

SpOptionButtonGroup.Button = SpOptionButton;

export default SpOptionButtonGroup;
