import { call, all, takeEvery } from 'redux-saga/effects';
import { fetchUserRequest, updateUserRequest, logoutRequest, reserveVirtualAccount } from '../../api/user'; // Import your API functions

import { 
  fetchUser, 
  updateUser, 
  logout, 
  requestAccount
} from '../actions/user'; // Import your action creators

import { 
  resolvePromiseAction, 
  rejectPromiseAction 
} from '@adobe/redux-saga-promise';

import { setAccessToken, translateErrorResponse } from '../../utils/utils'; // Function to translate errors

// Worker saga for fetching user data
export function* fetchUserSaga(action) {
  try {
    const response = yield call(fetchUserRequest);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Worker saga for updating user data
export function* updateUserSaga(action) {
  try {
    const response = yield call(updateUserRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ?? (response?.data ?? {})); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Worker saga for updating user data
export function* requestAccountSaga(action) {
  try {
    const response = yield call(reserveVirtualAccount, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ?? (response?.data ?? {})); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Worker saga for logging out
export function* logoutSaga(action) {
  try {
    yield call(logoutRequest);
    yield setAccessToken();
    yield call(resolvePromiseAction, action); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield setAccessToken();
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Watcher saga
export default function* userSaga() {
  yield all([
    takeEvery(fetchUser.trigger, fetchUserSaga), // Watch for fetchUser trigger action
    takeEvery(updateUser.trigger, updateUserSaga), // Watch for updateUser trigger action
    takeEvery(logout.trigger, logoutSaga), // Watch for logout trigger action
    takeEvery(requestAccount.trigger, requestAccountSaga), // Watch for request account trigger action
  ]);
}
