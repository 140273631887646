import React from 'react';
import { Select, Spin } from 'antd';

const SpSelect = ({ options = [], defaultValue, onChange, filterOption=false, showSearch=false, loading = false, ...rest }) => {
  const handleChange = (key) => {
    // Find item in options by key
    const selectedItem = options.find(option => option.key === key);
   
    if (typeof onChange === "function") {
      onChange(selectedItem);
    }
  };

  return (
    <Select
      style={{width: '100%'}}
      labelInValue={false}
      filterOption={filterOption}
      notFoundContent={loading ? <Spin size="small" /> : null}
      {...rest}
      options={options}
      loading={loading}
      onChange={handleChange}
      showSearch={showSearch}
      defaultValue={defaultValue}
    />
  );
};

export default SpSelect;
