import { Outlet } from "react-router-dom";
import withUserData from "../../HOC/withUserData";
import { redirectAfterDelay } from "../../utils/utils";
import useCustomNavigate from "../../hooks/useCustomNavigate";

const SigninIndex = ({ loggedInAsGuest = true, isLoggedIn }) => {
  const { getRedirectUrl } = useCustomNavigate();

  if (!loggedInAsGuest && isLoggedIn) {
    redirectAfterDelay(getRedirectUrl());
  }
  
  return (
    <div className="bg-white">
      <Outlet />
    </div>
  );
};

export default withUserData(SigninIndex);
