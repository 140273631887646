import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import withUserData from "../../HOC/withUserData";
import useIdleTimer from './../../hooks/useIdleTimer';
import { useDispatch } from "react-redux";
import { fetchTransactionDetails } from "../../redux/actions/transactions";
import TransactionSuccessful from "./Statuses/TransactionSuccessful";
import TransactionPending from "./Statuses/TransactionPending";
import TransactionInitiated from "./Statuses/TransactionInitiated";
import ServiceInterruption from "./Statuses/ServiceInterruption";
import PaymentCanceled from "./Statuses/PaymentCanceled";
import TransactionFailed from "./Statuses/TransactionFailed";
import { fetchUser } from "../../redux/actions/user";


const TransactionCompleted = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [idleTime, setIdleTime] = useState(1000);
    const [txData, setTxData] = useState(null);

    const {
        transactionReference = null,
        paymentStatus = "success"
    } = location?.state ?? {};


    //Temporary fix to duplicate location entry in history
    useEffect(() => {
        if (!transactionReference) {
            navigate(-1);
        }
    }, [navigate, transactionReference]);

    const onIdle = useCallback(() => {

        const status = (txData?.status ?? 'initiated')?.toLowerCase();

        const requeriedStatuses = ['pending', 'initiated'];

        if (transactionReference && requeriedStatuses.includes(status)) {
            setIdleTime(prevIdleTime => prevIdleTime + 1000);

            dispatch(fetchTransactionDetails({ transactionReference })).then(res => {
                setTxData(res || {});
                dispatch(fetchUser());
            });
        }

    }, [dispatch, txData, transactionReference]);

    useIdleTimer(onIdle, idleTime);

    const status = txData?.status?.toLowerCase();

    
    if (status === "canceled" || ['cancelled', 'canceled'].includes(paymentStatus)) {
        return <PaymentCanceled transactionReference={transactionReference} txData={txData} />
    }

    if (status === "success") {
        return <TransactionSuccessful txData={txData} />
    }

    if (['failed', 'refund'].includes(status)) {
        return <TransactionFailed txData={txData} />
    }

    if (status === "pending") {
        return <TransactionPending transactionReference={transactionReference} setData={setTxData} />
    }

    if (status === "error") {
        return <ServiceInterruption transactionReference={transactionReference} />
    }

    return <TransactionInitiated transactionReference={transactionReference} setData={setTxData} />;
};

export default withUserData(TransactionCompleted);
