import { useState, useCallback } from 'react';
import { resolveTransactionRequest } from '../api/transaction';
import { message } from 'antd';

const useResolveTransaction = (transactionId, props) => {
    const { showNotification = true } = props || {};

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const resolveTransaction = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await resolveTransactionRequest(transactionId);
            setData(response.data?.data);
            if (showNotification) {
                message.success(response?.data?.message);
            }

            setIsLoading(false);
        } catch (err) {
            setData(err?.response?.data?.data);
            setError(err?.response?.data?.message);

            if (showNotification) {
                message.error(err?.response?.data?.message);
            }

            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }, [transactionId, showNotification]);

    return { isLoading, data, error, resolveTransaction };
};

export default useResolveTransaction;
