import React from "react";
import WavyBuddies from "../../assets/svgs/wavy-buddies.svg";
import SpPageTransition from "../../sp-components/SpPagination/SpPagination";

function Layout({
  children,
  title,
  subTitle,
}) {
  return (
    <SpPageTransition>
      <div className="auth" style={{ marginTop: 24 }}>
        <div className="centered_text">
          <h3>{title}</h3>
          <p>{subTitle}</p>
        </div>
        <div className="layout__inner">{children}</div>
      </div>
    </SpPageTransition>
  );
}

export default Layout;
