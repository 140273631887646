import React from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { shallowEqual, useSelector } from "react-redux";
import { getModalVisible } from "../../redux/selectors/modal";
import LceedataFooter from "../Footer/Footer";
import { redirectAfterDelay } from "../../utils/utils";
import { SpBackArrow, SpLogo } from "../Svgs";
import useCustomNavigate from "../../hooks/useCustomNavigate";

const AppLayout = ({ children, hasSideMenu }) => {
  const isModalVisible = useSelector(getModalVisible, shallowEqual);

  const { navigate, historyCount } = useCustomNavigate();

  const handleClick = () => {
    if (historyCount > 1) {
      navigate(-1);
    }
    else {
      redirectAfterDelay('/');
    }
  }

  return (
    <Layout className={`blank-layout ${isModalVisible ? "blur" : ""}`}>
      <Content className="blank-layout__wrapper">
        <div className="blank-layout__header">
          <div className="back" onClick={handleClick}>
            <SpBackArrow />
          </div>
          <div className="img__wrapper" onClick={() => redirectAfterDelay('/')}>
           <SpLogo style={{cursor: 'pointer'}} />
          </div>
          {/* <div className="blank-layout__header-empty"></div> */}
        </div>
        <Content className="dashboard__content layout__child">
          {children}
        </Content>
      </Content>
      <LceedataFooter />
    </Layout>
  );
};

export default AppLayout;
